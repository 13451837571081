import {ref} from 'vue'
import Api from '@/http/modules/index'
const {
  // 款项类型下拉
  'GET/financial_flows/drop/payment_type_drop_list': paymentListApi,
} = Api


/**
 * 返回啊支付类型选项或者映射
 * @param type string
 * @returns array
 */
export const paymentTypeOption = (type?: string) => {
  // 获取款项类型下拉
  return paymentListApi().then((rep: any) => {
    return rep.reduce((arr: any[], item: any) => {
      if(item.children && item.children.length > 0){
        item.children.map((_item: any) => {
          let str = (item.number || '') + item.name + ' ' + (_item.number || '') + _item.name
          arr.push({label: str, id: str})
        })
      } else {
        let str = (item.number || '') + item.name
        arr.push({label: str, id: str})
      }
      return arr
    }, [])
  })
  /* return [
    { label: '空白', id: 'E099' },
    { label: '翻译收入', id: 'E001' },
    { label: '译员劳务采购', id: 'E002' },
    { label: '资产采购', id: 'E003' },
    { label: '费用报销', id: 'E004' },
    { label: '往来款', id: 'E005' },
    { label: '其他', id: 'E006' },
    { label: '银行手续费', id: 'E007' },
    { label: '职工薪酬', id: 'E008' },
    { label: '房租物管水电费', id: 'E009' },
    { label: '译员劳务采购退回', id: 'E010' },
    { label: '翻译收入退回', id: 'E011' },
    { label: '提现', id: 'E012' },
    { label: '筹资', id: 'E013' },
    { label: '货币转换', id: 'E014' },
    { label: '冻结', id: 'E015' },
    { label: '税款', id: 'E016' },
    { label: '翻译收入-内部交易', id: 'E017' },
    { label: '译员劳务采购-内部交易', id: 'E018' },
    { label: '平台服务费用', id: 'E019' },
    { label: '平台服务收入', id: 'E020' },
    { label: '其他业务收入', id: 'E021' },
    { label: '学堂收入', id: 'E022' },
  ] */
}

/**
 * 款项类型映射
 */

export const paymentType: any = { 
  'E099': '', 'E001': '翻译收入', 'E002': '译员劳务采购', 'E003': '资产采购', 'E004': '费用报销', 'E005': '往来款', 'E006': '其他', 
  'E007': '银行手续费', 'E008': '职工薪酬', 'E009': '房租物管水电费', 'E010': '译员劳务采购退回', 'E011': '翻译收入退回', 'E012': '提现', 
  'E013': '筹资', 'E014': '货币转换', 'E015': '冻结', 'E016': '税款', 'E017': '翻译收入-内部交易', 'E018': '译员劳务采购-内部交易',
}

/**
 * 获取询证函下拉类型或映射
 * @param type {string} 需要返回的类型
 * @returns {Array}
 */
export const confirmationOption = (type?: string) => {
  return [
    {label: '函证附件', id: 'broker'},
    {label: '回函附件', id: 'broker_reply'},
  ]
}


export const statusMap = (type?: string) => {
  return {
    unLocked: '未锁定',
    locked: '已锁定',
    paid: '支付完成',
  }
}

export const invoiceTypeMap = (type?: string): any => {
  if(type === 'option'){
    return [
      {label: '电子发票', id: 'ElectronicInvoice'},
      {label: '纸质发票', id: 'PaperInvoice'},
      {label: '单据', id: 'Voucher'},
      {label: '文件', id: 'File'},
      {label: '单据(政府性票据)', id: 'VoucherGovernment'},
      {label: 'Invoice', id: 'Invoice'},
      {label: '往来', id: 'Intercourse'},
    ]
  }
  return {
    ElectronicInvoice: '电子发票',
    PaperInvoice: '纸质发票',
    Voucher: '单据',
    File: '文件',
    VoucherGovernment: '单据(政府性票据)',
    Invoice: 'Invoice',
    Intercourse: '往来',
    Empty: '',
  }
}

export const invoiceStatusMap = (type?: string): any => {
  if(type === 'option'){
    return [
      {label: '未使用', id: 'UnUsed'},
      {label: '已使用', id: 'Used'},
      {label: '未识别', id: 'Unidentified'},
      {label: '发票重复', id: 'Repeat'},
    ]
  }
  return {
    UnUsed: '未使用',
    Used: '已使用',
    Unidentified: '未识别',
    Repeat: '发票重复',
    Empty: '',
  }
}

export const invoiceAmountMap: any = {
  "unitPrice": "单价",
  "amount": "金额",
  "Amount": "金额",
  "itemName": "商品名称",
  "quantity": "数量",
  "date": "日期",
  "item": "项目",
  "consumption": "金额",
  "payment": "支付",
  "price": "价格",
  "name": "商品名称",
  "specification": "规格",
  "taxPeriod": "税款所属时期",
  "voucherNumber": "原凭证号",
  "taxType": "税种",
  "cabinClass": "舱位等级",
  "carrier": "航班号",
  "flightDate": "飞行日期",
  "freeBaggageAllowance": "免费行李",
  "seatClass": "客票类别",
  "validFromDate": "有效期",
  "departureStation": "起点",
  "flightTime": "飞行时间",
  "arrivalStation": "终点",
  "validToDate": "到期日",
  "flightNumber": "班机号码",
  "Number": "序号",
  "number": "编号",
  "carType": "车型",
  "city": "城市",
  "endPlace": "终点",
  "mileage": "里程",
  "pickUpTime": "上车时间",
  "remarks": "备注",
  "startPlace": "起点",
  "tax": "税额",
  "taxRate": "税率",
  "unit": "单位",
  "departureTime": "出发时间",
  "fare": "票价",
  "passengerInfo": "乘客信息",
  "passengerName": "乘客人",
  "saleInfo": "销售地",
  "seatNumber": "座位号",
  "seatType": "座位类型",
  "ticketCode": "车票代码",
  "ticketGate": "检票口",
  "ticketNumber": "车票号码",
  "trainNumber": "火车号码",
  "AmountInWords": "大写金额",
  "formType": "来自类型",
  "invoiceCode": "发票代码",
  "invoiceNumber": "发票号码",
  "title": "发票标题",
  "dropOffTime": "下车时间",

  "licensePlateNumber": "车牌号",
  "checkCode": "校验码",
  "drawer": "开票人",
  "invoiceAmountPreTax": "发票税前金额",
  "invoiceDate": "开票日期",
  "invoiceTax": "发票税额",
  "invoiceType": "发票类型",
  "machineCode": "机器编号",
  "passwordArea": "密码区",
  "printedInvoiceCode": "机打代码",
  "printedInvoiceNumber": "机打发票号码",
  "purchaserBankAccountInfo": "购买方银行账户信息",
  "purchaserContactInfo": "购买方联系方式",
  "purchaserName": "购买方姓名",
  "purchaserTaxNumber": "购买方纳税人识别号",
  "recipient": "收款人",
  "reviewer": "复核",
  "sellerBankAccountInfo": "卖方银行信息",
  "sellerContactInfo": "销售方地址、电话",
  "sellerName": "销售方姓名",
  "sellerTaxNumber": "销售方纳税人识别号",
  "specialTag": "特殊标记",

  "vinCode": "车架号/车辆识别代码",
  "totalAmount": "总金额",
  "totalAmountInWords": "总金额大写",
  "invoiceDetails": "发票详情",
  "brandMode": "厂牌型号",
  "certificateNumber": "证书编号(登记编号)",
  "commodityInspectionNumber": "商品检验编号",
  "engineNumber": "发动机号码",
  "importCertificateNumber": "导入证书编号",
  "invoiceAmount": "价税合计",
  "invoiceAmountCn": "发票金额大写",
  "issuer": "开票人",
  "origin": "产地",
  "passengerLimitNumber": "限乘人数",
  "preTaxAmount": "税前金额",
  "purchaseCode": "购买方名称及身份证号码/组织结构代码",
  "sellerAddress": "卖方地址",
  "sellerBankAccount": "卖方银行账号",
  "sellerContact": "卖方联系方式",
  "sellerDepositaryBank": "卖方银行信息",
  "taxAuthoritiesCode": "税务机关代码",
  "taxAuthoritiesInfo": "税务机关信息",
  "taxAuthoritiesName": "税务机关单位",
  "taxCode": "税控码",
  "taxPaymentNumber": "纳税编号",
  "tonnage": "吨位",
  "vehicleType": "车辆类型",

  "applicationDate": "申请日期",
  "endTime": "结束时间",
  "phoneNumber": "手机号码",
  "serviceProvider": "提供服务",
  "startTime": "开始时间",
  "rideDetails": "搭乘信息",
  "insurance": "保险费",

  "endorsement": "签注",
  "serialNumber": "印刷序号",
  "agentCode": "销售单位代号",
  "caacDevelopmentFund": "民航发展基金",
  "otherTaxes": "其他税费",
  "validationCode": "验证码",
  "issueCompany": "发行公司",
  "internationalFlightSign": "国际航班标志",
  "pnrCode": "PNR 编码",
  "idCardNumber": "身份证号码",
  "promptMessage": "提示信息",
  "fuelSurcharge": "燃油附加费",
  "issueDate": "发行日期",
  "flights": "航班信息",

  "idcardNo": "身份证号码",
  "time": "出发时间",
  "acceptanceDate": "接收日期",
  "payeeAccountNumber": "收票人账号",
  "acceptorName": "承兑人信息全称",
  "subDraftNumber": "子票据编号",
  "draftStatus": "票据状态",
  "issuerName": "出票人全称",
  "draftNumber": "票据编号",
  "agreementNumber": "协议编号",
  "issuerAccountNumber": "出票人账号",
  "acceptorAccountNumber": "承兑人账号",
  "assignability": "能否转让",
  "payeeName": "收款单位",
  "payeeAccountBank": "收票人开户银行",
  "acceptorBankNumber": "承兑人银行账号",
  "acceptorAccountBank": "承兑人开户银行",
  "issuerAccountBank": "出票人开户银行",

  "payerCreditCode": "付款人信用代码",
  "additionalInfo": "其他信息",
  "payerName": "付款人名称",
  "orderNumber": "订单号",
  "description": "商品说明",
  "paymentMethod": "付款方式",
  "recipientName": "接收单位",
  "paymentTime": "支付时间",
  "taxNumbe": "纳税人识别号",
  // "name": "纳税人名称",
  "taxAuthorityName": "税务机关",
  "taxClearanceDetails": "详情",
  "sellerPhoneNumber": "机打代码",
  "purchaserAddress": "买方地址",
  "sellerCode": "卖方身份证号码",
  "purchaserCode": "买方身份证号码",
  "marketBankAccountInfo": "开户银行、账号",
  "vehicleAdministrationName": "转入地车辆管理所名称",
  "marketAddress": "二手市场地址",
  "marketTaxNumber": "二手市场纳税人识别号",
  "marketPhoneNumber": "二手市场电话号码",
  "entranceName": "入口站",
  "exitName": "出口站",
  "deliveryInfo": "寄件人信息",
  "shopName": "店铺名称",
  "transactionTime": "交易时间",
  "shoppingDetails": "商品详情",
  "address": "地址",
  "roomNo": "房间号",
  "numberOfGuests": "客人数量",
  "checkInDate": "签入日期，到店日期",
  "roomRate": "房间价格",
  "memberNumber": "成员",
  "phone": "电话",
  "postCode": "邮政编码",
  "totalConsumption": "总金额",
  "departureDate": "离开时间",
  "fax": "传真",
  "roomType": "房间类型",
  "consumptionDetails": "消费详情",
  "receiptTime": "付款时间",
  "contactNumber": "电话",
  "receiptDate": "付款日期",
  "shopAddress": "店铺地址",
  "receiptDetails": "商品详情",
  "purchaserTaxCode": "购买方纳税识别号",
  "cashier": "收银员",
  "remark": "标准",
}

export const overworkMap: any = {
  'ProjectOverTime': '项目加班',
  'ProjectOverTimeToSalary': '项目加班换薪',
  'DailyOverTime': '日常加班',
  'WorkDayOverTime': '工作日',
  'HolidayOverTime': '周末',
  'FestivalOverTime': '法定节假日',
}

export const baddebtMap: any = (type?: string) => {
  if(type){
    return [
      // {label: '确认坏账-待处理', id: 'CONFIRMATION_UNCONFIRMED'},
      {label: '确认坏账-已同意', id: 'CONFIRMATION_CONFIRMED'},
      {label: '确认坏账-已拒绝', id: 'CONFIRMATION_REJECTED'},
      {label: '申报坏账-已同意', id: 'APPLICATION_CONFIRMED'},
    ]
  }
  return {
    // 已同意 已拒绝 待处理 重新收款
    'CONFIRMATION_UNCONFIRMED': '待处理',
    'CONFIRMATION_CONFIRMED': '已确认',
    'CONFIRMATION_REJECTED': '已拒绝',
    'APPLICATION_CONFIRMED': '已同意',
    'RECHARGE': '重新收款',
    'NOT_INITIATED': '未开始',
    'CLOSED': '已关闭',
  }
}

export const baddebtOrderMap: any = (type?: string | boolean) => {
  if(type){
    return [
      {label: '未开始', id: 'NOT_INITIATED'},
      {label: '待处理', id: 'TO_BE_PROCESSED'},
      {label: '部分处理', id: 'IN_PROGRESS'},
      {label: '已处理', id: 'PROCESSED'},
      {label: '已关闭', id: 'CLOSED'},
      {label: '已申报', id: 'ALREADY_DECLARED'},
    ]
  }
  return {
    'NOT_INITIATED': '未开始',
    'TO_BE_PROCESSED': '待处理',
    'IN_PROGRESS': '部分处理',
    'PROCESSED': '已处理',
    'CLOSED': '已关闭',
    'ALREADY_DECLARED': '已申报',
  }

}